import Button from "@mui/material/Button";

export default function BotaoFaleConosco(props) {
  return (
    <Button
      onClick={() => {
        window.open(
          "https://api.whatsapp.com/send?phone=5555999042235&text=Ol%C3%A1. Preciso de ajuda com o portal!"
        );
      }}
      variant="contained"
      {...props}>
      {props.children || "FALE CONOSCO"}
    </Button>
  );
}
